.navbar-container {
    display: flex;
    align-items: center;
    background-color: #000; 
    margin: 10px;
  }

  .divider {
    background-color: #868686; /* Color of the divider */
    width: 1px;
    height: 25px; /* Adjust height as needed */
  }
  
  .logo, .site-title, .navbar {
    padding: 0 10px;
  }
  
  .logo img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5); 
    transition: opacity 0.3s ease; /* Smooth transition for the opacity */
  }

  .logo img:hover {
    opacity: 0.7; /* Decrease the opacity on hover */
  }
  
  .site-title {
    padding: 10px;
    margin: 0; /* Remove any default margin */
  }
  
  .site-title h1 {
    margin: 0; /* Remove any default margin */
  }
  
  .byline {
    text-align: right;
    font-size: 1rem; /* Adjust the font size as needed */
    margin: 0; /* Remove any default margin */
  }
  
  .bug-busters {
    color: #32a852; /* Use a specific shade of green */
  }
  
  
  .divider {
    background-color: grey; /* Color of the divider */
    width: 1px;
    height: 30px; /* Adjust height as needed */
  }
  
  .nav-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .nav-item {
    margin: 0 10px;
  }
  
  .nav-item a {
    display: flex; /* Ensures that the icon and label are inline */
    align-items: center; /* Centers them vertically */
    text-decoration: none;
    color: whitesmoke; /* This color will apply to the text, not the icon */
    font-weight: bold;
    gap: 5px; /* Adds a gap between the icon and the text label */
    transition: border-bottom 0.3s; /* Smooth transition for the border */
    border-bottom: 2px solid transparent; /* The border will be transparent by default */
  }
  
  .nav-item a:hover {
    border-bottom: 2px solid; /* The color will be set by an additional class */
  }
  
  /* Specific colors for each nav item on hover */
  .nav-item:nth-child(1) a:hover { border-bottom-color: #00ff00; } /* Green */
  .nav-item:nth-child(2) a:hover { border-bottom-color: #149edc; } 
  .nav-item:nth-child(3) a:hover { border-bottom-color: #964B00; } /* Brown */
  .nav-item:nth-child(4) a:hover { border-bottom-color: 'grey'; } 


  /* Existing styles above */

@media (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .logo, .site-title {
    padding: 10px; /* Increased padding for better touch targets */
  }

  .nav-list {
    flex-direction: column;
    align-items: flex-start; /* Aligns items to the start of the flex container */
  }

  .nav-item {
    width: 100%; /* Makes each nav item take the full width */
  }

  .nav-item a {
    justify-content: space-between; /* Spreads the icon and text */
    width: 100%; /* Ensures the anchor tag fills the li element */
    padding: 10px 0; /* Adds padding for touch targets */
  }
  
  .divider {
    display: none; /* Hides the divider on smaller screens */
  }
}


.nav-toggle {
  display: none; /* Hidden by default */
  background: none;
  border: none;
  font-size: 2em; /* Adjust as necessary */
  color: #fff; /* White color */
  cursor: pointer;
}

@media (max-width: 768px) {
  /* Other responsive styles */

  .nav-toggle {
    display: block; /* Show the toggle button on small screens */
    margin-left: auto; /* Push it to the end of the navbar */
  }

  .navbar.show, .divider.show {
    display: block;
  }

  .navbar, .divider {
    display: none; /* Hide the navbar and divider by default on small screens */
  }
}