.food-detail-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    border: 1px solid #444;
    border-radius: 8px;
    background-color: #333;
    color: #fff;
  }
  
  .food-detail-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .food-icon-placeholder {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    background-color: #555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .food-stats {
    width: 50%;
    border-collapse: collapse;
  }

  .food-stats .food-stat-item {
    display: table-row;
  }
  
  .food-stats .food-stat-label {
    width: 150px; /* Fixed width for labels */
    font-weight: bold;
    color: #fff;
    text-align: right;
    padding-right: 10px;
    vertical-align: top;
  }
  
  .food-stats td {
    padding: 5px 10px;
  }

  .biome-header {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .biome-detail-container {
    margin: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 8px;
}

.food-detail-container-biome {
  width: 800px;
  margin: 8px;
  padding: 20px;
  border: 1px solid #444;
  border-radius: 8px;
  background-color: #333;
  color: #fff;
}
  