/* Settings.css */

.settings-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #000;
    border-radius: 10px;
}

.filter-card {
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    color: #fff;
}

.filter-card h2 {
    margin-top: 0;
}

.filter-card p {
    margin-bottom: 10px;
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.biome-tag, .cooking-station-tag {
    background-color: #444;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

.biome-tag:hover, .cooking-station-tag:hover {
    opacity: 1;
}

.biome-tag.selected, .cooking-station-tag.selected {
    opacity: 1;
    border: 2px solid #fff;
}
