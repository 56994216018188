:root {
  --primary-bg-color: black;
  --secondary-bg-color: #333;
  --tertiary-bg-color: #444;
  --quaternary-bg-color: #555;
  --table-cell-bg-color: #222;
  --text-color: white;
  --highlight-color: #ddd;
  --bar-bg-color: #e0e0e0;
  --bar-color: #76c7c0;
  --fork-tag-text-color: black;
  --bar-text-color: black;
}

body {
  background-color: var(--primary-bg-color);
  color: var(--text-color);
  margin: 0;
  font-family: Arial, sans-serif;
}

.App {
  padding: 10px;
}

.food-table {
  width: 100%;
  border-collapse: collapse;
}

.food-table thead th {
  position: sticky;
  top: 0;
  background-color: var(--primary-bg-color);
  z-index: 1;
}

.food-table th,
.food-table td {
  border: 1px solid var(--highlight-color);
  padding: 8px;
  text-align: left;
}

.food-table th {
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  cursor: pointer;
}

.food-table th div {
  margin-top: 5px;
}

.food-table tr:nth-child(even) {
  background-color: var(--tertiary-bg-color);
}

.food-table tr:hover {
  background-color: var(--quaternary-bg-color);
}

.food-table td {
  background-color: var(--table-cell-bg-color);
}

.food-name-cell {
  display: flex;
  align-items: center;
}

.number-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.tag-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.center {
  text-align: center;
}

.fork-type-tag {
  padding: 2px 8px;
  border-radius: 4px;
  display: inline-block;
  color: var(--fork-tag-text-color);
}

.food-link {
  color: var(--text-color); /* Make the link color same as text color */
  text-decoration: none; /* Remove underline */
  cursor: pointer; /* Show pointer cursor on hover */
}

.food-link:hover {
  text-decoration: underline; /* Optionally add underline on hover */
}


.bar-container {
  width: 100%;
  background-color: var(--bar-bg-color);
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar {
  height: 18px;
  background-color: var(--bar-color);
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  font-weight: bold;
}

.bar-text {
  position: relative;
  z-index: 1;
  color: var(--bar-text-color);
  font-weight: bold;
}

/* Home.css */


.home-page {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.splash-container {
  text-align: center;
  margin-top: 20px;
  flex: 1;
}

.splash-container img {
  width: 100%;
  max-width: 800px;
  min-width: 320px;
  height: auto;
  min-height: 400px;
  border-radius: 10px;
  border: 3px solid #444;
}

.text {
  flex: 1;
  max-width: 600px;
  text-align: left;
}

.text h1, .text h2 {
  color: #f0db4f;
  letter-spacing: 1px;
}

.text h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.text h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.text p {
  line-height: 1.6;
  margin-bottom: 15px;
}

main {
  background: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 8px;
}

main p {
  margin-bottom: 10px;
}

/* Footer.css */
.footer {
  text-align: center;
  padding: 4px 0px 0px 5px;
  background-color: #222;
  color: #f0db4f;
  position: fixed;
  width: 100%;
  bottom: 0;
  font-size: 0.9rem;
  margin-top: auto;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer p {
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.footer a {
  margin-left: auto;
}

